<template>
  <div id="hottip-top-header" class="d-print-none">
    <div class="main-component">
      <!-- 모바일 통합 검색 -->
      <div v-if="showMobileSearch" class="d-flex justify-content-between">
        <button type="button" @click="toggleMobileSearch"><i class="icon-history-back" /></button>
        <input v-model="search" type="text" class="hottip-search-input" placeholder="Hot Tip 검색" @keydown.enter="clickSearch"/>
        <button type="button" @click="clickSearch"><i class="icon-search" /></button>
      </div>
      <!-- 공통 헤더 -->
      <div v-else class="header-items">
        <div class="kb-form-dropdown title" :class="{'is-active': dropdownToggle.title}">
          <button class="kb-form-dropdown-btn" @click.stop="dropdownToggle.title = !dropdownToggle.title">
            <strong class="text me-2" v-if="boardMst">{{boardMst.boardTitle}}</strong>
            <strong class="text me-2" v-else-if="$route.name === 'HotTipSearch' && $route.query.postBadgeCdDcd === HotTipBoardBadgeCdDcd.BEST">우수 Tip 모아보기</strong>
            <strong class="text me-2" v-else>Hot Tip 홈</strong>
            <i class="icon-arrow" :class="{'rotate': dropdownToggle.title}" />
          </button>
          <div class="kb-form-dropdown-options">
            <div class="dropdown-option">
              <ul class="dropdown-option-list" @click.stop="dropdownToggle.title = !dropdownToggle.title">
                <li class="dropdown-option-item kb-mouse-cursor">
                  <a class="dropdown-option-link" :class="{'is-active': $route.name === 'HotTipMain'}" @click="$router.push({name: 'HotTipMain'})">
                    <span class="dropdown-option-text">Hot Tip 홈</span>
                  </a>
                </li>
                <li v-for="(toggleItem,idx) in toggleItems" :key="idx" class="dropdown-option-item kb-mouse-cursor">
                  <a class="dropdown-option-link" :class="{'is-active': toggleItem.boardId === boardId}" @click="$router.push({name: 'HotTipBoard', params:{boardId: toggleItem.boardId}})">
                    <span class="dropdown-option-text">{{toggleItem.boardTitle}}</span>
                  </a>
                </li>
                <li class="dropdown-option-item kb-mouse-cursor">
                  <a class="dropdown-option-link" :class="{'is-active': $route.name === 'HotTipSearch' && $route.query.postBadgeCdDcd === HotTipBoardBadgeCdDcd.BEST }" @click="$router.push({name: 'HotTipSearch',query:{'postBadgeCdDcd':HotTipBoardBadgeCdDcd.BEST}})">
                    <span class="dropdown-option-text">우수 Tip 모아보기</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="isMobile" class="header-util left">
          <div class="kb-form-dropdown file" :class="{'is-active': dropdownToggle.file}">
            <button class="kb-form-dropdown-btn" @click.stop="dropdownToggle.file = !dropdownToggle.file">
              <i class="icon-more" />
            </button>
            <div class="kb-form-dropdown-options">
              <div class="dropdown-option">
                <ul class="dropdown-option-list" @click.stop="dropdownToggle.file = !dropdownToggle.file">
                  <li class="dropdown-option-item">
                    <FileDownLink file-key="HotTipGuide" :show-icon="false" :add-link-class="['dropdown-option-link']" :add-text-class="['dropdown-option-text']" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isMobile" class="header-util right">
          <button  v-if="boardMst && boardMst.postWrtYn === 'Y'" type="button" class="me-2" @click="moveToBoardWrite"><i class="icon-pencil" /></button>
          <button type="button" @click="toggleMobileSearch"><i class="icon-search" /></button>
        </div>
        <template v-else>
          <div class="d-flex">
            <div v-if="!hideSearch" class="kb-form-search">
              <div class="kb-form-search-field">
                <input type="text" class="kb-form-control" v-model.trim="search" placeholder="Hot Tip 검색" @keyup.prevent.stop.enter="clickSearch">
                <button type="button" class="kb-btn-search" @click.stop="clickSearch" ><i class="icon-search">검색</i></button>
                <button class="kb-btn-search-delete" @click.stop="deleteSearch"><i class="icon-tag-delete"></i></button>
              </div>
            </div>
            <button type="button" class="kb-btn kb-btn-light-green kb-btn-sm rounded-lg" @click="opinionModal = !opinionModal">
              <i class="icon-opinion"></i>
              <span class="text">Hot Tip 개선의견</span>
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
  <HotTipOpinionModal v-if="opinionModal" v-model="opinionModal" :title="title" />
</template>

<script>
import {computed, onMounted, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import {
  getHotTipBoardMst, HotTipBoardBadgeCdDcd, hotTipBoardIds,
} from "@/assets/js/modules/learn/learn-hot-tip";
import {goToWrite} from "@/assets/js/modules/board/board-common";
import FileDownLink from "@/components/common/FileDownLink.vue";
import HotTipOpinionModal from "@/components/prep/HotTipOpinionModal.vue";

export default {
  name: 'HotTipHeader',
  components: {HotTipOpinionModal, FileDownLink},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showMessage} = useAlert();
    const session = computed(() => store.state.auth.session);
    const isMobile = computed(() => store.state.auth.isMobile);
    const boardId = computed(() => route.params.boardId);
    const boardMst = computed(() => getHotTipBoardMst(boardId.value));
    const dropdownToggle = reactive({
      title: false,
      file: false,
    });
    const toggleItems = computed(() => store.state.boardmst.objects.filter(x => hotTipBoardIds.includes(x.boardId)).map(y => {
      return {boardId: y.boardId, boardTitle: y.boardMst.boardTitle};
    }).sort((a, b) => b.boardId.localeCompare(a.boardId)))
    const search = ref(null);
    const showMobileSearch = ref(false);
    const hideSearch = computed(() => route.name === 'BoardEdit' || route.name === 'BoardView');
    const opinionModal = ref(false);
    const title = 'Hot Tip 개선의견';

    const moveToBoardWrite = () => {
      goToWrite(session.value.lrnerId, boardMst);
    }

    const clickSearch = () => {
      dropdownToggle.title = false;
      if(search.value){
        if(search.value.length < 2){
          showMessage('정확한 검색을 위해 두글자 이상 입력해주세요.');
        }else{
          router.push({name: 'HotTipSearch', query: {word: search.value}});
        }
      }else{
        showMobileSearch.value = false;
      }
    }

    const deleteSearch = () => {
      search.value = null;
    }

    const toggleMobileSearch = () => {
      if(showMobileSearch.value){
        deleteSearch();
        router.push({query: {word: ''}});
      }
      showMobileSearch.value = !showMobileSearch.value;
    }

    watch(() => route.query.word, () => {
      if(route.name != 'HotTipSearch'){
        deleteSearch();
      }
    })

    onMounted(() => {
      // 검색어
      if((route.name === 'HotTipSearch' || route.name === 'HotTipBoard') && route.query.word){
        search.value = route.query.word;
        if(isMobile.value) showMobileSearch.value = true;
      }
    })

    return {
      isMobile,
      dropdownToggle,
      toggleItems,
      boardId,
      boardMst,
      search,
      showMobileSearch,
      hideSearch,
      HotTipBoardBadgeCdDcd,
      moveToBoardWrite,
      clickSearch,
      deleteSearch,
      toggleMobileSearch,
      opinionModal,
      title
    }
  }
}
</script>