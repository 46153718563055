<template>
  <main class="kb-main">
    <HotTipHeader v-if="showHottipHeader" />
    <!-- main-content -->
    <div class="main-content main-component">
      <!-- board-editor-container -->
      <div class="board-editor-container">
        <!-- editor-aside -->
        <div class="editor-aside">
          <div class="aside-inner h-100 justify-content-between overflow-y-auto" style="min-height:auto; max-height:calc(100vh); overflow-x:hidden;" v-if="!isLoading">
            <!-- editor-aside > aside-header -->
            <div class="aside-header">
              <!-- kb-form-row -->
              <div class="kb-form-row">
                <input type="text" v-model="param.postTitle" class="kb-form-control" placeholder="제목을 입력하세요" :readonly="isReadOnly" maxlength="90">
              </div>
              <div class="kb-form-row" v-if="boardMst.userScrtYn == 'Y'">
                <input type="text" v-model="param.userNickNm" class="kb-form-control" placeholder="닉네임을 입력하세요" :readonly="isReadOnly" maxlength="50">
              </div>
              <!-- kb-form-row -->
              <div class="kb-form-row" v-if="boardCateList.length > 1">
                <div class="kb-form-dropdown" :class="{'is-active' : (isCateToggle && !isReadOnly) }">
                  <button class="kb-form-dropdown-btn" @click="isCateToggle = !isCateToggle"><strong class="text">{{ cateNm }}</strong><i class="icon-arrow"></i></button>
                  <div class="kb-form-dropdown-options">
                    <div class="dropdown-option">
                      <ul class="dropdown-option-list">
                        <li v-for="item in boardCateList" :key="item.boardCateSn" class="dropdown-option-item">
                          <a class="dropdown-option-link" @click="clickCategory(item)"><span class="dropdown-option-text">{{item.cateNm}}</span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kb-form-row" v-if="boardMst.postTopFixYn === 'Y' && param.postLvl === 0">
                <span class="text-muted fs-8">게시판 상단 고정</span>
                <div>
                  <div class="kb-form-check kb-form-check-inline">
                    <input type="radio" class="kb-form-check-input" id="top_fix_y" v-model="param.topFixYn" value="Y" :disabled="isReadOnly"/>
                    <label for="top_fix_y" class="kb-form-check-label"><span class="text">설정</span></label>
                  </div>
                  <div class="kb-form-check kb-form-check-inline">
                    <input type="radio" class="kb-form-check-input" id="top_fix_n" v-model="param.topFixYn" value="N" :disabled="isReadOnly"/>
                    <label for="top_fix_n" class="kb-form-check-label"><span class="text">미설정</span></label>
                  </div>
                </div>
              </div>
              <!-- 게시판 설정 항목 -->
              <board-custom-info v-model:custom-infos="customInfos" :is-read-only="isReadOnly" v-if="customInfos.length > 0"/>
              <!-- kb-form-row -->
              <div class="kb-form-row" v-if="boardMst.postHashTagYn === 'Y'">
                <span class="text-muted fs-8">해시태그</span>
                <input type="text" v-model="hashTag" class="kb-form-control" placeholder="단어 입력 후 엔터(Enter)" :readonly="isReadOnly" @keyup.enter="addHashTag">
                <template v-for="(tag, idx) in param.hashTag.split(hashTagSeparator)" :key="idx">
                  <div v-if="tag" class="badge badge-brown m-1">
                    <span class="badge-text me-1">{{tag}}</span>
                    <button @click="removeHashTag(idx)"><i class="icon-tag-delete"></i></button>
                  </div>
                </template>
              </div>
              <!-- kb-form-row -->
              <div class="kb-form-row" v-if="boardMst.boardLayoutCd == 'thumbList'">
                <div class="kb-form-file" title="썸네일 파일 등록">
                  <label for="thumb-file" class="kb-form-file-btn kb-btn kb-btn-secondary">
                    <span class="text" v-if="param.thumbFileNm == ''">썸네일 이미지 등록</span>
                    <span class="text" v-else >{{param.thumbFileNm}}</span>
                  </label>
                  <input type="file" ref="uploadThumbFile" @change="clickThumb" class="kb-form-file-input kb-mouse-cursor" id="thumb-file" :disabled="isReadOnly">
                </div>
              </div>
            </div>
            <!-- editor-aside > aside-files -->
            <div class="aside-files" v-if="boardMst.atchMaxCnt > 0">
              <ul class="file-list">
                <li class="file-item" v-for="(item, idx) in files.files" :key="idx">
                  <div class="file">
                    <p class="filename" title="">
                      <span class="filename-base">{{item.fileNm}}</span><span class="filename-extension"></span>
                      <button type="button" @click="removeFile(item)" class="ms-1" :disabled="isReadOnly"><i class="icon-x"></i></button>
                    </p>
                    <p class="filesize">{{convertToStorageBytes(item.fileSz)}}</p>
                  </div>
                </li>
              </ul>
              <div class="file-upload">
                <div class="kb-form-file">
                  <label for="inp-file" class="kb-form-file-btn kb-btn kb-btn-secondary"><span class="text">파일첨부</span></label>
                  <input type="file" ref="uploadFiles" @change="clickFileAtch" class="kb-form-file-input kb-mouse-cursor" id="inp-file" :disabled="isReadOnly">
                </div>
                <p class="kb-form-file-tip">파일첨부는 최대 {{boardMst.atchMaxCnt}}개까지 가능합니다.</p>
              </div>
            </div>
            <!-- editor-aside > aside-actions -->
            <div class="aside-actions">
              <loading-div v-if="isReadOnly" />
              <div v-else>
                <button v-if="isHotTipBoard" class="kb-btn kb-btn-wide kb-btn-secondary" :disabled="isReadOnly" @click="clickSaveTemp"><span class="text">임시저장</span></button>
                <button class="kb-btn kb-btn-wide kb-btn-secondary" :disabled="isReadOnly" @click="showPreviewModal"><span class="text">미리보기</span></button>
                <button class="kb-btn kb-btn-wide kb-btn-dark" :disabled="isReadOnly" @click="clickSave"><span class="text">등록</span></button>
                <div v-if="param.stt == '01'" class="d-flex w-100">
                  <button class="kb-btn kb-btn-secondary w-50" @click="exitBoardPage"><span class="text">나가기</span></button>
                  <button class="kb-btn kb-btn-primary  w-50" @click="deletePost(param.comPostSn, exitBoardPage)"><span class="text">삭제</span></button>
                </div>
                <button v-else class="kb-btn kb-btn-wide kb-btn-secondary" @click="exitBoardPage"><span class="text">나가기</span></button>
              </div>
            </div>
          </div>
        </div>
        <!-- editor-contents -->
        <div class="editor-contents">
          <!-- editor-contents > content-body -->
          <div class="editor-content-body">
            <div ref="pageEditor" class="editor-area"></div>
          </div>
          <div v-if="!isLoading && boardMst.postNoti" class="editor-content-footer">
            <div class="content-source">
              <div class="source-text">
                <pre class="lh-base" style="white-space: pre-wrap;">{{boardMst.postNoti}}</pre>
              </div>
            </div>
          </div>
          <!-- editor-contents > content-footer -->
<!--          <div class="content-footer">-->
<!--            <div class="footer-column footer-column-right">-->
<!--              <span class="footer-byte" id="nowByte">0 / 3000</span>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <!-- //board-editor-container -->
    </div>
    <!-- //main-content -->
  </main>
  <div v-if="isUploading" class="fixed-top bg-dark opacity-75 d-flex justify-content-center align-items-center vw-100 vh-100">
    <LoadingDiv />
    <h3 class="text-primary fs-3">썸네일 이미지 업로드 중입니다.</h3>
  </div>
  <BoardPreviewModal v-model:show="toggle.previewModal" :board-mst="boardMst" :post="param" :custom-infos="customInfos" :files="files.files" />
</template>

<script>
import {boardEditSetup} from "@/assets/js/modules/board/board-common";
import LoadingDiv from "@/components/common/LoadingDiv";
import BoardCustomInfo from "@/components/board/BoardCustomInfo";
import HotTipHeader from "@/components/prep/HotTipHeader.vue";
import BoardPreviewModal from "@/components/board/BoardPreviewModal.vue";

export default {
  name: 'BoardEdit',
  components: {LoadingDiv, BoardCustomInfo, HotTipHeader, BoardPreviewModal},
  setup: boardEditSetup
}
</script>